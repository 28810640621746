.contact-form {
    background-color: #fff;
    padding: 40px;
    max-width: 600px;
    margin: 80px auto;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .contact-form:hover {
    transform: translateY(-10px); /* Adds a slight lift on hover */
  }
  
  .contact-form h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #2C3E50;
    font-size: 2.5rem;
    font-weight: 600;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #34495E;
    font-size: 1.1rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #f8f8f8;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #1ABC9C;
    box-shadow: 0 0 8px rgba(26, 188, 156, 0.3);
    outline: none;
  }
  
  .form-group textarea {
    height: 120px;
  }
  
  .btn-submit {
    width: 100%;
    background-color: #1ABC9C;
    color: white;
    padding: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease-in-out, transform 0.2s;
  }
  
  .btn-submit:hover {
    background-color: #16A085;
    transform: scale(1.05);
  }
  
  .btn-submit:active {
    transform: scale(0.98);
  }
  