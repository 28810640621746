/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #0000FF;
  color: white;
}
.navbar ul {
  display: flex;
  list-style: none;
}
.navbar ul li {
  margin: 0 1rem;
}
.navbar ul li a {
  color: white;
  text-decoration: none;
}

/* Hero Section */
.hero {
  height: 100vh;
  background: url('./AI.webp') center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.hero-content h1 {
  font-size: 3rem;
}
.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.btn {
  background-color: #007bff;
  padding: 0.75rem 1.5rem;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btn:hover {
  background-color: #0056b3;
}

/* Section Styles */
.section {
  padding: 3rem 1rem;
  text-align: center;
}
.section h2 {
  margin-bottom: 1rem;
}
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}
.card {
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.contact-section {
  background-color: #e9ecef;
  padding: 4rem 1rem;
}

/* Footer */
footer {
  padding: 1rem;
  background-color: #333;
  color: white;
  text-align: center;
}
