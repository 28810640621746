.navbar {
  background-color: #2C3E50;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center; /* Center the contents of the navbar */
}

/* New wrapper for the content */
.navbar-content {
  margin-left: -100px; /* Move the content 100px to the left, adjust as needed */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Keep the content stretched */
}

.logo-container {
  margin-left: 10px;
}

.large-logo {
  width: 200px;
  height: auto;
}

.navbar ul {
  list-style: none;
  display: flex;
  margin-right: 100px;
}

.navbar ul li {
  margin: 0 15px;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.navbar ul li a:hover {
  color: #1ABC9C;
}
